/* You can add global styles to this file, and also import other style files */
.tox .tox-edit-area__iframe {
    background-color: #191a32 !important;
    color: #fff !important;
}

.tox-statusbar {
    display: none !important;
}

.tox-notifications-container {
    display: none;
}

.ng2-smart-filters .ng-star-inserted {
    display: none;
}

.swal2-actions .swal2-cancel {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}

.swal2-actions .swal2-confirm {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
}