@import "~bootstrap/scss/bootstrap";
@import 'feather-icon/feather-icon';

@import 'theme/variables';
@import 'theme/admin_style';
@import 'theme/rtl';
// @import 'theme/chartist.scss';
@import 'theme/datatable';
@import 'icons/icoicon/icons';
// Flag contents
@import 'flag-icon/flag-icon-base';
@import 'flag-icon/flag-icon-more';
@import 'flag-icon/flag-icon-list';
@import 'icons/themify/themify-icons';
@import '~dropzone/dist/min/dropzone.min.css';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';


.text-14 {
    font-size: 14px;
}

::ng-deep.swal2-confirm {
    margin: 5px;
}

::ng-deep.swal2-cancel {
    margin: 5px;
}

textarea {
    resize: none;
}

.ngx-pagination .current {
    background: #313131 !important;
    border-radius: 6px;
    // color: #000 !important;
}

.gm-style .gm-style-iw-c {
    border-radius: 50%;
    background-color: transparent;
    padding: unset !important;
    overflow: unset !important;

}

.gm-style .gm-style-iw-d {
    // height: 80px;
    // width: 80px;
    overflow: unset !important;
}

.gm-style .gm-style-iw-tc {
    display: none;
}

.pointer {
    cursor: pointer;
}